define("discourse/plugins/chat/discourse/components/chat-message/blocks/elements/button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/helpers/replace-emoji", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _dButton, _concatClass, _replaceEmoji, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Button extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "interacting", [_tracking.tracked], function () {
      return false;
    }))();
    #interacting = (() => (dt7948.i(this, "interacting"), void 0))();
    async createInteraction() {
      this.interacting = true;
      try {
        await this.args.createInteraction(this.args.definition.action_id);
      } finally {
        this.interacting = false;
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "createInteraction", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @id={{@definition.action_id}}
          @isLoading={{this.interacting}}
          @translatedLabel={{replaceEmoji @definition.text.text}}
          @action={{this.createInteraction}}
          class={{concatClass
            "block__button"
            (if @definition.style (concat "btn-" @definition.style))
          }}
        />
      
    */
    {
      "id": "HUcNNaFd",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"block__button\",[52,[30,1,[\"style\"]],[28,[32,2],[\"btn-\",[30,1,[\"style\"]]],null]]],null]]],[[\"@id\",\"@isLoading\",\"@translatedLabel\",\"@action\"],[[30,1,[\"action_id\"]],[30,0,[\"interacting\"]],[28,[32,3],[[30,1,[\"text\",\"text\"]]],null],[30,0,[\"createInteraction\"]]]],null],[1,\"\\n  \"]],[\"@definition\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message/blocks/elements/button.js",
      "scope": () => [_dButton.default, _concatClass.default, _helper.concat, _replaceEmoji.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Button;
});