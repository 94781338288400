define("discourse/plugins/chat/discourse/components/chat/header/icon/unread-indicator", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/controllers/preferences-chat", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _preferencesChat, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_UNREAD_COUNT = 99;
  class ChatHeaderIconUnreadIndicator extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatTrackingStateManager", [_service.service]))();
    #chatTrackingStateManager = (() => (dt7948.i(this, "chatTrackingStateManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get urgentCount() {
      return this.args.urgentCount || this.chatTrackingStateManager.allChannelUrgentCount;
    }
    get mentionCount() {
      return this.args.mentionCount || this.chatTrackingStateManager.allChannelMentionCount;
    }
    get unreadCount() {
      return this.args.unreadCount || this.chatTrackingStateManager.publicChannelUnreadCount;
    }
    get hasUnreads() {
      return this.unreadCount > 0 || this.chatTrackingStateManager.hasUnreadThreads;
    }
    get indicatorPreference() {
      return this.args.indicatorPreference || this.currentUser.user_option.chat_header_indicator_preference;
    }
    get showUrgentIndicator() {
      if (this.onlyMentions) {
        return this.mentionCount > 0;
      }
      return this.urgentCount > 0 && this.#hasAnyIndicatorPreference([_preferencesChat.HEADER_INDICATOR_PREFERENCE_ALL_NEW, _preferencesChat.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS]);
    }
    get showUnreadIndicator() {
      return this.hasUnreads && this.#hasAnyIndicatorPreference([_preferencesChat.HEADER_INDICATOR_PREFERENCE_ALL_NEW]);
    }
    get urgentCountLabel() {
      const totalCount = this.onlyMentions ? this.mentionCount : this.urgentCount;
      return totalCount > MAX_UNREAD_COUNT ? `${MAX_UNREAD_COUNT}+` : totalCount;
    }
    get onlyMentions() {
      return this.#hasAnyIndicatorPreference([_preferencesChat.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS]);
    }
    #hasAnyIndicatorPreference(preferences) {
      if (!this.currentUser || this.indicatorPreference === _preferencesChat.HEADER_INDICATOR_PREFERENCE_NEVER) {
        return false;
      }
      return preferences.includes(this.indicatorPreference);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showUrgentIndicator}}
          <div class="chat-channel-unread-indicator -urgent">
            <div class="chat-channel-unread-indicator__number">
              {{this.urgentCountLabel}}
            </div>
          </div>
        {{else if this.showUnreadIndicator}}
          <div class="chat-channel-unread-indicator"></div>
        {{/if}}
      
    */
    {
      "id": "2Gk2Vc43",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUrgentIndicator\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-channel-unread-indicator -urgent\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-channel-unread-indicator__number\"],[12],[1,\"\\n          \"],[1,[30,0,[\"urgentCountLabel\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-channel-unread-indicator\"],[12],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/header/icon/unread-indicator.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatHeaderIconUnreadIndicator;
});