define("discourse/plugins/chat/discourse/components/chat-message/blocks/actions", ["exports", "discourse/plugins/chat/discourse/components/chat-message/blocks/element", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _element, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Actions = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="block__actions-wrapper">
      <div class="block__actions">
        {{#each @definition.elements as |elementDefinition|}}
          <div class="block__action-wrapper">
            <div class="block__action">
              <Element
                @createInteraction={{@createInteraction}}
                @definition={{elementDefinition}}
              />
            </div>
          </div>
        {{/each}}
      </div>
    </div>
  
  */
  {
    "id": "n7BEDl/L",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"block__actions-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"block__actions\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"elements\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"block__action-wrapper\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"block__action\"],[12],[1,\"\\n            \"],[8,[32,0],null,[[\"@createInteraction\",\"@definition\"],[[30,3],[30,2]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@definition\",\"elementDefinition\",\"@createInteraction\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message/blocks/actions.js",
    "scope": () => [_element.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "actions:Actions"));
  var _default = _exports.default = Actions;
});