define("discourse/plugins/chat/discourse/components/chat/drawer-routes/browse", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/browse-channels", "discourse/plugins/chat/discourse/components/chat/navbar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _discourseI18n, _browseChannels, _navbar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesBrowse extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatHistory", [_service.service]))();
    #chatHistory = (() => (dt7948.i(this, "chatHistory"), void 0))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-drawer-routes --browse">
          <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
            <navbar.BackButton @route="chat.channels" />
            <navbar.Title @title={{i18n "chat.browse.title"}} />
            <navbar.Actions as |a|>
              <a.NewChannelButton />
              <a.ToggleDrawerButton />
              <a.FullPageButton />
              <a.CloseDrawerButton />
            </navbar.Actions>
          </Navbar>
    
          {{#if this.chatStateManager.isDrawerExpanded}}
            <div class="chat-drawer-content">
              {{#each (array @params.currentTab) as |tab|}}
                <BrowseChannels @currentTab={{tab}} />
              {{/each}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "KU7X8xRN",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-drawer-routes --browse\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\"],[\"chat.channels\"]],null],[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[32,1],[\"chat.browse.title\"],null]]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"NewChannelButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"        \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[28,[32,2],[[30,3,[\"currentTab\"]]],null]],null]],null],null,[[[1,\"            \"],[8,[32,3],null,[[\"@currentTab\"],[[30,4]]],null],[1,\"\\n\"]],[4]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"a\",\"@params\",\"tab\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/browse.js",
      "scope": () => [_navbar.default, _discourseI18n.i18n, _helper.array, _browseChannels.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesBrowse;
});