define("discourse/plugins/chat/discourse/components/chat-messages-container", ["exports", "@ember/helper", "discourse/modifiers/on-resize", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _onResize, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatMessagesContainer = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      class="chat-messages-container"
      {{onResize @didResizePane (hash delay=100 immediate=true)}}
    >
      {{yield}}
    </div>
  
  */
  {
    "id": "qQebh2Wm",
    "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"chat-messages-container\"],[4,[32,0],[[30,1],[28,[32,1],null,[[\"delay\",\"immediate\"],[100,true]]]],null],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@didResizePane\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-messages-container.js",
    "scope": () => [_onResize.default, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "chat-messages-container:ChatMessagesContainer"));
  var _default = _exports.default = ChatMessagesContainer;
});