define("discourse/plugins/chat/discourse/components/chat-user-avatar", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse/helpers/concat-class", "discourse/helpers/user-avatar", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _concatClass, _userAvatar, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatUserAvatar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    get avatar() {
      return (0, _template.htmlSafe)((0, _userAvatar.renderAvatar)(this.args.user, {
        imageSize: this.avatarSize
      }));
    }
    get interactive() {
      return this.args.interactive ?? true;
    }
    get avatarSize() {
      return this.args.avatarSize || "tiny";
    }
    get showPresence() {
      return this.args.showPresence ?? true;
    }
    get isOnline() {
      const users = (this.args.chat || this.chat).presenceChannel?.users;
      return this.showPresence && !!users?.find(_ref => {
        let {
          id,
          username
        } = _ref;
        return this.args.user?.id === id || this.args.user?.username === username;
      });
    }
    get userPath() {
      return (0, _url.userPath)(this.args.user.username);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class={{concatClass "chat-user-avatar" (if this.isOnline "is-online")}}
          data-username={{@user.username}}
        >
          {{#if this.interactive}}
            <a
              class="chat-user-avatar__container"
              href={{this.userPath}}
              data-user-card={{@user.username}}
            >
              {{this.avatar}}
            </a>
          {{else}}
            <span class="chat-user-avatar__container">
              {{this.avatar}}
            </span>
          {{/if}}
        </div>
      
    */
    {
      "id": "hnZknr1Z",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"chat-user-avatar\",[52,[30,0,[\"isOnline\"]],\"is-online\"]],null]],[15,\"data-username\",[30,1,[\"username\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"interactive\"]],[[[1,\"        \"],[10,3],[14,0,\"chat-user-avatar__container\"],[15,6,[30,0,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,\"\\n          \"],[1,[30,0,[\"avatar\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"chat-user-avatar__container\"],[12],[1,\"\\n          \"],[1,[30,0,[\"avatar\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-user-avatar.js",
      "scope": () => [_concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatUserAvatar;
});