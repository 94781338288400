define("discourse/plugins/chat/discourse/components/chat/message-creator/members-count", ["exports", "@glimmer/component", "truth-helpers", "discourse/helpers/concat-class", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _truthHelpers, _concatClass, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MembersCount extends _component.default {
    get countLabel() {
      return (0, _discourseI18n.i18n)("chat.direct_message_creator.members_counter", {
        count: this.args.count,
        max: this.args.max
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class={{concatClass
            "chat-message-creator__members-count"
            (if (gte @count @max) "-reached-limit")
          }}
        >
          {{this.countLabel}}
        </div>
      
    */
    {
      "id": "x9p0Y77Q",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"chat-message-creator__members-count\",[52,[28,[32,1],[[30,1],[30,2]],null],\"-reached-limit\"]],null]],[12],[1,\"\\n      \"],[1,[30,0,[\"countLabel\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@count\",\"@max\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/members-count.js",
      "scope": () => [_concatClass.default, _truthHelpers.gte],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MembersCount;
});