define("discourse/plugins/chat/discourse/components/channel-title/index", ["exports", "discourse/helpers/concat-class", "discourse/plugins/chat/discourse/components/channel-icon", "discourse/plugins/chat/discourse/components/channel-name", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _concatClass, _channelIcon, _channelName, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatChannelTitle = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      class={{concatClass
        "chat-channel-title"
        (if @channel.isDirectMessageChannel "is-dm" "is-category")
      }}
    >
      <ChannelIcon @channel={{@channel}} />
      <ChannelName @channel={{@channel}} />
  
      {{#if @isUnread}}
        <div class="unread-indicator {{if @isUrgent '-urgent'}}"></div>
      {{/if}}
  
      {{#if (has-block)}}
        {{yield}}
      {{/if}}
    </div>
  
  */
  {
    "id": "QzIjxXzZ",
    "block": "[[[1,\"\\n  \"],[10,0],[15,0,[28,[32,0],[\"chat-channel-title\",[52,[30,1,[\"isDirectMessageChannel\"]],\"is-dm\",\"is-category\"]],null]],[12],[1,\"\\n    \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n    \"],[8,[32,2],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"      \"],[10,0],[15,0,[29,[\"unread-indicator \",[52,[30,3],\"-urgent\"]]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@channel\",\"@isUnread\",\"@isUrgent\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/channel-title/index.js",
    "scope": () => [_concatClass.default, _channelIcon.default, _channelName.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "channel-title:ChatChannelTitle"));
  var _default = _exports.default = ChatChannelTitle;
});