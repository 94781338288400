define("discourse/plugins/chat/discourse/components/chat/header/icon", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/helpers/d-icon", "discourse/lib/get-url", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/header/icon/unread-indicator", "discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _concatClass, _dIcon, _getUrl, _discourseI18n, _unreadIndicator, _getUserChatSeparateSidebarMode, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatHeaderIcon extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get showUnreadIndicator() {
      if (this.chatStateManager.isFullPageActive && this.site.desktopView) {
        return false;
      }
      return !this.currentUserInDnD;
    }
    get currentUserInDnD() {
      return this.args.currentUserInDnD || this.currentUser.isInDoNotDisturb();
    }
    get chatSeparateSidebarMode() {
      return (0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(this.currentUser);
    }
    get isActive() {
      return this.args.isActive || this.chatStateManager.isFullPageActive || this.chatStateManager.isDrawerActive;
    }
    get title() {
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never && this.site.desktopView) {
        return (0, _discourseI18n.i18n)("sidebar.panels.forum.label");
      }
      return (0, _discourseI18n.i18n)("chat.title_capitalized");
    }
    get icon() {
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never && this.site.desktopView) {
        return "shuffle";
      }
      return "d-chat";
    }
    get href() {
      if (this.site.mobileView && this.chatStateManager.isFullPageActive) {
        return (0, _getUrl.default)("/chat");
      }
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never) {
        return (0, _getUrl.default)(this.chatStateManager.lastKnownAppURL || "/");
      }
      if (this.chatStateManager.isDrawerActive) {
        return (0, _getUrl.default)("/chat");
      }
      return (0, _getUrl.default)(this.chatStateManager.lastKnownChatURL || "/chat");
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="header-dropdown-toggle chat-header-icon">
          <DButton
            @href={{this.href}}
            tabindex="0"
            class={{concatClass "icon" "btn-flat" (if this.isActive "active")}}
            title={{this.title}}
          >
            {{~icon this.icon~}}
            {{#if this.showUnreadIndicator}}
              <ChatHeaderIconUnreadIndicator
                @urgentCount={{@urgentCount}}
                @unreadCount={{@unreadCount}}
                @indicatorPreference={{@indicatorPreference}}
              />
            {{/if}}
          </DButton>
        </li>
      
    */
    {
      "id": "Xk80NG8S",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"header-dropdown-toggle chat-header-icon\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,\"tabindex\",\"0\"],[16,0,[28,[32,1],[\"icon\",\"btn-flat\",[52,[30,0,[\"isActive\"]],\"active\"]],null]],[16,\"title\",[30,0,[\"title\"]]]],[[\"@href\"],[[30,0,[\"href\"]]]],[[\"default\"],[[[[1,[28,[32,2],[[30,0,[\"icon\"]]],null]],[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"          \"],[8,[32,3],null,[[\"@urgentCount\",\"@unreadCount\",\"@indicatorPreference\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@urgentCount\",\"@unreadCount\",\"@indicatorPreference\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/header/icon.js",
      "scope": () => [_dButton.default, _concatClass.default, _dIcon.default, _unreadIndicator.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatHeaderIcon;
});