define("discourse/plugins/chat/discourse/components/chat/message-creator/channel", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/plugins/chat/discourse/components/channel-title", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _channelTitle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Channel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get tracking() {
      return this.args.item.tracking;
    }
    get isUrgent() {
      return this.args.item.model.isDirectMessageChannel ? this.hasUnreads || this.hasUrgent : this.hasUrgent;
    }
    get hasUnreads() {
      return this.tracking?.unreadCount > 0;
    }
    get hasUrgent() {
      return this.tracking?.mentionCount > 0 || this.tracking?.watchedThreadsUnreadCount > 0;
    }
    get hasUnreadThreads() {
      return this.args.item.unread_thread_count > 0;
    }
    get showIndicator() {
      return this.hasUnreads || this.hasUnreadThreads || this.hasUrgent;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="chat-message-creator__chatable -category-channel"
          data-disabled={{not @item.enabled}}
        >
          <ChannelTitle
            @channel={{@item.model}}
            @isUnread={{this.showIndicator}}
            @isUrgent={{this.isUrgent}}
          />
        </div>
      
    */
    {
      "id": "bNLclP//",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__chatable -category-channel\"],[15,\"data-disabled\",[28,[32,0],[[30,1,[\"enabled\"]]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@channel\",\"@isUnread\",\"@isUrgent\"],[[30,1,[\"model\"]],[30,0,[\"showIndicator\"]],[30,0,[\"isUrgent\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@item\"],false,[]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/channel.js",
      "scope": () => [_truthHelpers.not, _channelTitle.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Channel;
});