define("discourse/plugins/chat/discourse/components/chat-message-text", ["exports", "@glimmer/component", "@ember/template", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-message-collapser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _template, _discourseI18n, _chatMessageCollapser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessageText extends _component.default {
    get isEdited() {
      return this.args.edited ?? false;
    }
    get isCollapsible() {
      return (0, _chatMessageCollapser.isCollapsible)(this.args.cooked, this.args.uploads);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-text">
          {{#if this.isCollapsible}}
            <ChatMessageCollapser
              @cooked={{@cooked}}
              @uploads={{@uploads}}
              @onToggleCollapse={{@onToggleCollapse}}
            />
          {{else}}
            {{htmlSafe @cooked}}
          {{/if}}
    
          {{#if this.isEdited}}
            <span class="chat-message-edited">({{i18n "chat.edited"}})</span>
          {{/if}}
    
          {{yield}}
        </div>
      
    */
    {
      "id": "vz8nk+T9",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-text\"],[12],[1,\"\\n\"],[41,[30,0,[\"isCollapsible\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@cooked\",\"@uploads\",\"@onToggleCollapse\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[32,1],[[30,1]],null]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"isEdited\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-edited\"],[12],[1,\"(\"],[1,[28,[32,2],[\"chat.edited\"],null]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@cooked\",\"@uploads\",\"@onToggleCollapse\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-text.js",
      "scope": () => [_chatMessageCollapser.default, _template.htmlSafe, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMessageText;
});